<template>
  <b-row>
    <b-col>
      <b-card>
        <b-row>
          <h2>
            Crie a Descrição :
          </h2>
        </b-row>
        <b-row class="mt-2">
          <b-col md="4">
            <b-form-group
              label="Selecione o Cliente :"
              label-for="cliente"
            >
              <v-select
                v-model="cliente"
                label="nome"
                :options="opcoescliente"
                placeholder="Cliente"
                @input="buscarContrato()"
              />
            </b-form-group>
          </b-col>
          <b-col md="8">
            <b-form-group
              label="Selecione o Contrato :"
              label-for="contrato"
            >
              <v-select
                v-model="contrato"
                label="numero_contrato"
                :options="opcoescontrato"
                placeholder="Contrato"
                :disabled="!cliente"
                @input="BuscarObra()"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="4">
            <b-form-group
              label="Selecione a Obra :"
              label-for="obra"
            >
              <v-select
                v-model="obra"
                label="nome"
                :options="opcoesobra"
                placeholder="Obra"
                :disabled="!contrato || !cliente"
                @input="BuscarDisciplina()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Selecione a Disciplina :"
              label-for="disciplina"
            >
              <v-select
                v-model="disciplina"
                label="nome"
                :options="opcoesdisciplina"
                placeholder="Disciplina"
                :disabled="!contrato || !cliente || !obra"
                @input="BuscarAtividade()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Selecione a Atividade :"
              label-for="atividade"
            >
              <v-select
                v-model="atividade"
                label="nome"
                :options="opcoesatividade"
                placeholder="Atividade"
                :disabled="!contrato || !cliente || !obra || !disciplina"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group

              label="Descrição :"
              label-for="descricao"
            >
              <b-form-input
                id="descricao"
                v-model="descricao"
                placeholder="Descrição"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-button
              variant="primary"
              block
              @click="SalvarCliente()"
            >
              Salvar
            </b-button>
          </b-col>
          <b-col>
            <b-button
              variant="primary"
              block
              @click="LimparCampos()"
            >
              Limpar Campos
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BFormGroup, BRow } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'IndexDescricao',
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      url: 'admin/descricao',
      data: {},
      // ---------- Dados V-Model ----------- \\
      id: null,
      cliente: null,
      opcoescliente: [],
      contrato: null,
      opcoescontrato: [],
      obra: null,
      opcoesobra: [],
      disciplina: null,
      opcoesdisciplina: [],
      atividade: null,
      opcoesatividade: [],
      descricao: null,
    }
  },
  watch: {
    cliente(newV) {
      if (!newV) this.contrato = null
    },
    contrato(newV) {
      if (!newV) this.obra = null
    },
    obra(newV) {
      if (!newV) this.disciplina = null
    },
    disciplina(newV) {
      if (!newV) this.atividade = null
    },
  },

  async created() {
    this.BuscaObra()
    await this.$http.get('admin/clientes_ver').then(cliente => {
      this.opcoescliente = cliente.data
    })
  },
  methods: {
    async BuscaObra() {
      this.$loading(true)
      this.edicao = JSON.parse(localStorage.getItem('admin/edicao'))
      if (this.edicao) {
        this.descricaoeditar = JSON.parse(localStorage.getItem('admin/edicao'))
        this.id = this.descricaoeditar.id
        this.descricao = this.descricaoeditar.descricao
        this.cliente = this.descricaoeditar.cliente
        this.contrato = this.descricaoeditar.contrato
        this.obra = this.descricaoeditar.obra
        this.disciplina = this.descricaoeditar.disciplina
        this.atividade = this.descricaoeditar.atividade
        this.$loading(false)
      }
      if (!this.edicao) {
        this.obras = JSON.parse(localStorage.getItem('admin/obras'))
        await this.$http.get(`admin/cliente_buscar/${this.obras.data.obra.cliente_id}`)
          .then(clientes => {
            this.cliente = clientes.data
          })
        await this.$http.get(`admin/contrato_buscar/${this.obras.data.obra.contrato_id}`)
          .then(contratos => {
            this.contrato = contratos.data
          })
        await this.$http.get(`admin/atividade_descricao/${this.obras.data.obra.id}`)
          .then(atividadedescricao => {
            this.atividade = atividadedescricao.data
          })
        await this.$http.get(`admin/disciplina_descricao/${this.obras.data.obra.id}`)
          .then(disciplinadescricao => {
            this.disciplina = disciplinadescricao.data
          })
        this.obra = this.obras.data.obra
        this.$loading(false)
      }
    },
    async buscarContrato() {
      if (this.cliente === null) return
      await this.$http.get(`admin/contrato_ver/${this.cliente.value}`).then(contrato => {
        this.opcoescontrato = contrato.data
      })
    },
    async BuscarObra() {
      if (this.contrato === null) return
      await this.$http.get(`admin/obra_ver/${this.contrato.value}`).then(obra => {
        this.opcoesobra = obra.data
      })
    },
    async BuscarDisciplina() {
      if (this.obra === null) return
      await this.$http.get(`admin/disciplina_ver/${this.obra.value}`).then(disciplina => {
        this.opcoesdisciplina = disciplina.data
      })
    },
    async BuscarAtividade() {
      if (this.disciplina === null) return
      await this.$http.get(`admin/atividade_ver/${this.disciplina.value}`).then(atividade => {
        this.opcoesatividade = atividade.data
      })
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'NOTIFICAÇÃO',
        variant: cor,
        solid: true,
      })
    },
    LimparCampos() {
      this.id = null
      this.cliente = null
      this.contrato = null
      this.obra = null
      this.disciplina = null
      this.atividade = null
      this.descricao = null
    },
    SalvarCliente() {
      const obj = {
        cliente_id: this.cliente.id,
        contrato_id: this.contrato.id,
        obra_id: this.obra.id,
        disciplina_id: this.disciplina.disciplina_id,
        atividade_id: this.atividade.atividade_id,
        descricao: this.descricao,
      }
      this.$loading(true)
      if (this.id === null) {
        this.$http.post('admin/descricao', obj).then(resp => {
          if (resp.data.success) {
            this.Notificacao('success', resp.data.success)
            this.$loading(false)
            this.$router.push({
              name: 'adm-descricao',
            })
          }
          if (resp.data.error) {
            this.Notificacao('danger', resp.data.error)
            this.$loading(false)
          }
        })
      }
      if (this.id) {
        this.$http.put(`admin/descricao/${this.id}`, obj).then(resp => {
          if (resp.data.success) {
            this.Notificacao('success', resp.data.success)
            this.$loading(false)
            this.$router.push({
              name: 'adm-descricao',
            })
          }
          if (resp.data.error) {
            this.Notificacao('danger', resp.data.error)
            this.$loading(false)
          }
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
